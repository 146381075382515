/* eslint-disable no-unused-vars */
/**
 * @description: request method
 */
export enum RequestEnum {
      GET = 'GET',
      POST = 'POST',
      PUT = 'PUT',
      DELETE = 'DELETE',
}

/**
 * @description:  contentType
 */
export enum ContentTypeEnum {
      // json
      JSON = 'application/json;charset=UTF-8',
      // form-data qs
      FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
      // form-data  upload
      FORM_DATA = 'multipart/form-data;charset=UTF-8',
}
