/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'ant-design-vue';
import { AxiosError } from 'axios';
import { remove } from 'lodash';

import { useI18n } from './useI18n';

import { BaseDataResponseModel } from '@/services/types';

export interface ApiError {
   method: string;
   error: BaseDataResponseModel<any> | unknown;
}
const { t } = useI18n();
export function useError() {
  function getApiErrorResponse(error: AxiosError<BaseDataResponseModel<any>>, method_name = '', custom_message = ''): ApiError {
    const data = error.response?.data;
    showApiErrorNotification({ status: data?.status.code, message: data?.status.value, custom_message });
    return {
      method: method_name,
      error: data
    };
  }
  function showApiErrorNotification({ status, message, custom_message }: { status?: number | string, message?: string, custom_message?: string }): void {
    notification.error({
      message: t('error.an-error-occurred'),
      description: `${status} ~ ${custom_message || message}`,
      duration: 2.5
    });
  }

  function clearError(array: Array<ApiError>, method_name: string) {
    remove(array, error => error.method === method_name);
  }

  return {
    getApiErrorResponse,
    showApiErrorNotification,
    clearError
  };
}
