export const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : 'http://internal-DEV-ALB-13431810.eu-central-1.elb.amazonaws.com/';

// Binovative URLs
export const FORM_URL = (id: string | string[]): string => `${API_URL}binovative/v2/api/Checkups/${id}`;
export const SESSION_URL = `${API_URL}binovative/v2/api/usersessions`;
export const SUBMIT_ANSWERS_URL = `${API_URL}binovative/v2/api/UserAnswers`;
export const BASIC_RESULT_URL = `${API_URL}binovative/v2/api/Report`;
export const DETAIL_REPORT_URL = `${API_URL}binovative/v2/api/DetailReport`;
export const SESSION_TOKEN_EXCHANGE_URL = `${API_URL}binovative/v2/api/SessionTokenExchange`;
export const USER_SESSIONS_ADMIN_URL = `${API_URL}binovative/v2/api/usersessions/admin`;
export const FREE_PASS_URL = `${API_URL}binovative/v2/api/freepasses`;

// Payment URLs
export const PAYMENT_URL = `${API_URL}payments/v2/api/products/payment`;

